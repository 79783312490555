/*
  
  A popup must have the following standard actions
    1. close button
    2. toggle for background fade
    3. banner size selection
    4. debug

*/

export const GenericPopUpInit = () => {
  $('.popupFade-new').off('click', GenericPopUpClose).on('click', GenericPopUpClose);
}

export const GenericPopUpClose = () => {
  $('.popupFade-new').remove();
  $('.m-popup-new').remove();
}

